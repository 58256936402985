<template>
  <div class="actions">
    <router-link
      :to="{name: 'detail_vaccination', params: {uid: object.uid}}"
      class="btn btn-sm  btn-secondary"
    >
      <i class="icofont icofont-eye" />
    </router-link>
    <a
      href="javascript:void(0)"
      class="btn btn-sm btn-third"
      @click.prevent="deleting"
      v-if="is_super_admin || is_veterinaire || is_assistant_veto_major"
    >
      <i class="icofont icofont-close" />
    </a>
  </div>
</template>
  
  <script>
  import { DELETE_VACCINATION } from '../../../graphql/sanitaire'; 
  import { mapGetters } from 'vuex';
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return  {
  
          }
      },
      methods: {
          deleting(){
              this.$apollo.mutate({
                  mutation: DELETE_VACCINATION,
                  variables: {
                      "uid": this.object.uid
                  },
                  update: (data) => {
                      if(data) console.log(`Vaccination ${this.object.uid} deleted successfully`)
                      else console.log(`Error in deletting vaccination ${this.object.uid}`)
                  }
                })
          }
      },
      computed: {
        ...mapGetters({
          is_super_admin: 'auth/is_super_admin',
          is_veterinaire: 'auth/is_veterinaire',
          is_assistant_veto_major: 'auth/is_assistant_veto_major'
        })
      }
  
  
  }
  </script>
  
  <style lang="scss" scoped>
  .actions{
      a{
          margin-left: 5px;
      }
  }
  </style>